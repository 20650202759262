<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <!-- <v-icon>layers</v-icon> -->
            Item
            <v-spacer></v-spacer>
            <!-- <v-btn
              fab
              small
              style="z-index: 1; background: blue"
              dark
              @click="downloadItem"
            >
              <v-icon dark>cloud_download</v-icon>
            </v-btn> -->
            <v-download-column-select
              @onDownload="downloadItem"
              :showPDF="false"
            >
              DOWNLOAD ITEM RECORD
            </v-download-column-select>
            <!--<v-chip
              class="eac-add-button"
              @click="$router.push({ name: 'item-create' })"
              style="background: #1976d2"
              text-color="white"
            >
              <v-avatar>
                <v-icon color="white">add_circle</v-icon>
              </v-avatar>
              <slot>Add Item</slot>
            </v-chip> -->

            <add-button
              permission="subject-read"
              @action="$router.push({ name: 'item-create' })"
              >Add Item</add-button
            >
          </v-card-title>

          <v-card flat>
            <v-card outlined>
              <v-card-title class="title">
                <v-flex xs4>
                  <v-text-field
                    outlined
                    dense
                    hint="Atleast 3 characters"
                    flat
                    color="primary"
                    class="pa-0"
                    label="Search By Name"
                    v-model="searchAH"
                    @input="searchItem"
                    append-icon="search"
                  />
                </v-flex>
              </v-card-title>
            </v-card>
          </v-card>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            item-key="name"
            :server-items-length="form.items.meta.total"
            :search="search"
            :options.sync="pagination"
            :loading="form.loading"
            :footer-props="footerProps"
            :expanded.sync="expanded"
            :single-expand="singleExpand"
            show-expand
          >
            <!-- <template slot="items" slot-scope="props"> -->
            <template
              v-slot:item.data-table-expand="{ expand, isExpanded, item }"
            >
              <span v-if="item.notify">
                <v-icon
                  style="cursor: pointer"
                  color="error"
                  v-if="isExpanded"
                  @click="expand(!isExpanded)"
                >
                  indeterminate_check_box
                </v-icon>
                <v-icon
                  style="cursor: pointer"
                  color="primary"
                  v-else
                  @click="expand(!isExpanded)"
                >
                  add_circle
                </v-icon>
              </span>
              <span v-else>
                <v-icon style="cursor: no-drop"> add_circle </v-icon>
              </span>
            </template>
            <template v-slot:item.category="{ item }">
              {{
                findCategory(item.category_id)
                  ? findCategory(item.category_id).name
                  : ''
              }}
            </template>
            <template v-slot:item.action="{ item }">
              <!-- <v-btn
                small
                icon
                color="warning"
                class="pa-0 ma-1"
                @click="
                  $router.push({
                    name: 'item-edit',
                    params: { id: item.id },
                  })
                "
              >
                <v-icon small>edit</v-icon>
              </v-btn> -->
              <edit-button
                permission="subject-read"
                @agree="
                  $router.push({
                    name: 'item-edit',
                    params: { id: item.id },
                  })
                "
              />
              <delete-button
                permission="section-create"
                @agree="form.delete(item.id)"
              />
            </template>
            <!-- :class="'highlight'" @click="item.expanded = !item.expanded" -->
            <!-- <template v-slot:item="{ index, item }">
             
              <tr>
                <td class="text-xs-left">
                  {{ index + 1 }}
                </td>
                <td class="text-xs-left">{{ item.name }}</td>
                <td class="text-xs-left">{{ item.item_code }}</td>
                <td class="text-xs-left">{{ item.print_name }}</td>
                <td class="text-xs-left">
                  {{ parseFloat(item.sales_rate).numberFormat() }} /
                  {{ parseFloat(item.purchase_rate).numberFormat() }}
                </td>
                <td class="text-xs-left">
                  {{ parseFloat(item.min_sales_rate).numberFormat() }} /
                  {{ parseFloat(item.mrp_sales).numberFormat() }}
                </td>
                <td class="text-xs-left">
                  {{
                    findCategory(item.category_id)
                      ? findCategory(item.category_id).name
                      : ''
                  }}
                </td>
                <td>
                  <v-btn
                    small
                    icon
                    color="warning"
                    class="pa-0 ma-1"
                    @click="
                      $router.push({
                        name: 'item-edit',
                        params: { id: item.id },
                      })
                    "
                  >
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <delete-button
                    permission="section-create"
                    @agree="form.delete(item.id)"
                  />
                </td>
              </tr>
            </template> -->
            <!-- <template v-slot:expand="props"> -->
            <template v-slot:expanded-item="{ headers, item }">
              <td style="padding: 0px" :colspan="headers.length">
                <v-card
                  class="elevation-1"
                  v-if="item.openings.length > 0"
                  style="border-radius: 0; padding: 20px 0px; width: 100%"
                >
                  <p
                    class="notification"
                    style="
                      width: 90%;
                      display: flex;
                      justify-content: space-between;
                    "
                    v-if="item.notify"
                  >
                    Notification On:
                    <span class="n-title"> Min Qty: </span>
                    <span class="amount">{{ item.notify.min_qty }}</span>

                    <span class="n-title"> Max Qty: </span>
                    <span class="amount">{{ item.notify.max_qty }}</span>

                    <span class="n-title"> Reorder Level: </span>
                    <span class="amount">{{ item.notify.reorder_level }}</span>

                    <span class="n-title"> Taxable: </span>
                    <span class="amount" v-if="item.is_taxable"
                      ><v-icon class="taxIconCheck">check</v-icon></span
                    >
                    <span class="amount" v-else
                      ><v-icon class="taxIconClear">clear</v-icon></span
                    >

                    <span class="n-title"> Service Type: </span>
                    <span class="amount" v-if="item.is_service_type"
                      ><v-icon class="taxIconCheck">check</v-icon></span
                    >
                    <span class="amount" v-else
                      ><v-icon class="taxIconClear">clear</v-icon></span
                    >
                  </p>
                  <table style="padding: 0px 20px" width="100%" class="table">
                    <thead>
                      <tr>
                        <th
                          style="text-align: left; width: 20%"
                          class="text-xs-left"
                        >
                          <strong>WareHouse</strong>
                        </th>
                        <th style="text-align: left" class="text-xs-left">
                          <strong>Quantity</strong>
                        </th>
                        <th style="text-align: left" class="text-xs-left">
                          <strong>Rate </strong>
                        </th>
                        <th style="text-align: left" class="text-xs-left">
                          <strong>Amount</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(x, i) in item.openings"
                        :key="i"
                        style="height: 0px"
                      >
                        <td style="text-align: left" class="text-xs-left">
                          {{
                            findwareHouse(x.warehouse_id)
                              ? findwareHouse(x.warehouse_id).name
                              : ''
                          }}
                        </td>
                        <td style="text-align: left" class="text-xs-left">
                          {{ x.qty }}
                        </td>
                        <td style="text-align: left" class="text-xs-left">
                          {{ x.rate }}
                        </td>
                        <td style="text-align: left" class="text-xs-left">
                          {{ x.amount }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-card>
                <v-card v-else style="margin-left: 30px width:100%">
                  No Items found
                </v-card>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import Form from '@/library/Form';
import { passDataToExternalDomain } from '../../../../library/helpers';

const NepaliDate = require('nepali-date');
const nd = new NepaliDate();

export default {
  data: () => ({
    form: new Form(
      {
        name: '',
        print_name: '',
        item_code: '',
        category_id: '',
        is_service_type: 0,
        purchase_rate: '',
        sales_rate: '',
        mrp_sales: '',
        min_sales_rate: '',
        is_taxable: 0,
        item_data: {
          warehouse_id: '',
          qty: '',
          rate: '',
          unit: '',
        },
      },
      '/api/account/inventory/item'
    ),
    searchAH: '',
    expand: false,
    searchC: null,
    search: null,
    searchW: null,
    isLoading: false,
    rowsPerPageItems: [5, 10, 25, 50, 75, 100],
    pagination: {
      rowsPerPage: 10,
    },
    // categorySearchData: [],
    // wareHouseSearchData: [],
    categories: [],
    units: [],
    wareHouses: [],
    accountHeads: [],
    headers: [
      { text: '#', align: 'left', sortable: false, value: 'id', width: '20px' },
      { text: 'Name', value: 'name', align: 'left' },
      { text: 'Item Code', value: 'item_code', align: 'left', sortable: false },
      {
        text: 'Print Name',
        value: 'print_name',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Sales / Purchase Rate',
        value: 'sales_rate',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Min / Mrp Sales Rate',
        value: 'min_sales_rate',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Category',
        value: 'category_id',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Actions',
        value: 'action',
        sortable: false,
        align: 'right',
        width: 220,
      },
    ],
    footerProps: {
      'items-per-page-options': [10, 25, 50, 100, -1],
    },
    expanded: [],
    singleExpand: true,
  }),

  computed: {
    ...mapState(['batch']),
    // items() {
    //     return this.categorySearchData.map(entry => {
    //         return {Name: entry.name, id: entry.id};
    //     })
    // },
    // warehouses() {
    //     return this.wareHouseSearchData.map(entry => {
    //         return {Name: entry.name, id: entry.id};
    //     })
    // }
  },
  mounted() {
    // this.getUnits();
    this.getCategories();
    this.getWareHouses();
  },

  watch: {
    pagination: function () {
      this.get();
    },
    batch: function (value) {
      this.get();
    },
    searchC(val) {
      if (!val) return;
      // if (this.isLoading) return;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.isLoading = true;
        this.$rest
          .get('api/account/inventory/category?search=' + val)
          .then(({ data }) => {
            this.categorySearchData = data.data.map((res) => {
              return { name: res.name, id: res.id };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
    searchW(val) {
      if (!val) return;
      // if (this.isLoading) return;

      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.isLoading = true;
        this.isLoading = true;
        this.$rest
          .get('api/account/inventory/warehouse?search=' + val)
          .then(({ data }) => {
            this.wareHouseSearchData = data.data.map((res) => {
              return { name: res.name, id: res.id };
            });
          })
          .catch((err) => {})
          .finally(() => (this.isLoading = false));
      }, 500);
    },
  },

  methods: {
    queryString() {
      let json = this.pagination;
      return (
        '?' +
        Object.keys(json)
          .map(function (key) {
            if (![null, undefined].includes(json[key]))
              return (
                encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
              );
          })
          .join('&') +
        '&search=' +
        this.searchAH
      );
    },

    get(params) {
      let query = [null, undefined].includes(params)
        ? this.queryString()
        : params;
      this.form.get(null, query).then(({ data }) => {
        this.pagination.totalItems = data.meta.total;
        this.downloadUrl = data.download_url;
      });
    },
    editData(data) {
      this.categorySearchData = [];
      this.categorySearchData.push({
        name: data.parent_name,
        id: data.parent_id,
      });
    },

    save() {
      if (this.form.is_service_type) this.form.is_service_type = 1;
      else this.form.is_service_type = 0;

      if (this.form.is_taxable) this.form.is_taxable = 1;
      else this.form.is_taxable = 0;
      // this.form.parent_id = this.form.parent_id.id;
      this.form.store();
    },
    searchItem: function (v) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.get();
      }, 500);
    },
    downloadItem() {
      passDataToExternalDomain();
      setTimeout(() => {
        window.open(this.downloadUrl);
      }, 300);
    },
    getUnits() {
      this.$rest
        .get('api/account/inventory/units')
        .then(({ data }) => {
          this.units = data.data;
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
    getCategories() {
      this.$rest
        .get('api/account/inventory/category')
        .then(({ data }) => {
          this.categories = data.data;
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
    getWareHouses() {
      this.$rest
        .get('api/account/inventory/warehouse')
        .then(({ data }) => {
          this.wareHouses = data.data;
        })
        .catch((err) => {})
        .finally(() => (this.isLoading = false));
    },
    findUnit(unit_id) {
      return this.units.find((res) => res.id === unit_id);
    },
    findCategory(category_id) {
      return this.categories.find((res) => res.id === category_id);
    },
    findwareHouse(warehouse_id) {
      return this.wareHouses.find((res) => res.id === warehouse_id);
    },
  },
};
</script>
<style lang="scss">
tr.highlight {
  cursor: pointer;
}

.notification {
  margin-left: 20px;
  .n-title {
    margin-left: 20px;
    color: green;
  }
  .amount {
    color: red;
  }
}
.taxIconClear {
  color: red !important;
  font-size: 15px;
}
.taxIconCheck {
  color: blue !important;
  font-size: 15px;
}
</style>
